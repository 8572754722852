import IService from './iservice';
import config from "@/config";
import store from "@/store/store";

const inv_server_base = config[config.stage].inventory_server;
const html_server_base = config[config.stage].html;
const data_server_base = config[config.stage].data_server;
const files_server_base = config[config.stage].files_server;

class InventoryService extends IService {
  constructor(http) {
    super(http);
    this.store = store;
  }

  async searchVinOrStock(pattern) {
    try {
      const [vin_response, stock_response] = await Promise.all([
        this.http.get(`${ inv_server_base }/api/car/${ pattern }`),
        this.http.get(`${ inv_server_base }/api/car/stock/${ pattern }`)
      ]);
      const cars = [];

      if ( vin_response.data && !vin_response.data.error && vin_response.data.data )
        cars.push(...vin_response.data.data);

      if ( stock_response.data && !stock_response.data.error && stock_response.data.data )
        cars.push(...stock_response.data.data);

      return { error: false, data: cars };

    } catch (e) {
      return null;
    }
  }

  async getByVin(vin) {
    try {
      const res = await this.http.get(`${ inv_server_base }/api/car/${ vin }`);
      return res.data;
    } catch (e) {
      return null;
    }
  }

  async getMakes() {
    try {
      const res = await this.http.get(`${ inv_server_base }/api/car/filter/makes`);
      return res.data;
    } catch (e) {
      return null;
    }
  }

  async getModels(make) {
    try {
      let url = "";
      let query = {};

      if ( make ) {
        if ( Array.isArray(make) ) {
          url = `${ inv_server_base }/api/car/filter/models-by-make`;
          query = { make };

        } else {
          url = `${ inv_server_base }/api/car/filter/models-by-make/${ make }`;
        }
      } else {
        url = `${ inv_server_base }/api/car/filter/models`;
      }

      const res = await this.http.get(url, query);
      return res.data;
    } catch (e) {
      return null;
    }
  }

  async getBody() {
    try {
      let res = await this.http.get(`${ inv_server_base }/api/car/filter/body`);
      return res.data;
    } catch (e) {
      return null;
    }
  }

  async getPrices() {
    try {
      let res = await this.http.get(`${ inv_server_base }/api/car/filter/prices`);
      return res.data;
    } catch (e) {
      return null;
    }
  }

  async getHistoryReport(vin, source = 'db') {
    try {
      let res = await this.http.get(`${ inv_server_base }/api/history?vin=${ vin }&source=${ source }`);
      return res.data;
    } catch (e) {
      return null;
    }
  }

  async requestHistoryReport(vin) {
    try {
      let res = await this.http.get(`${ html_server_base }/carfax/getReport?vin=${ vin }`);
      return res.data;
    } catch (e) {
      return null;
    }
  }

  async getDescriptionReport(vin) {
    try {
      let res = await this.http.get(`${ inv_server_base }/api/car-history/description?vin=${ vin }`);
      return res.data;
    } catch (e) {
      return null;
    }
  }

  async getDescriptions(vin) {
    try {
      const res = await this.http.get(`${ inv_server_base }/api/car/descriptions/${ vin }`);
      return res.data;
    } catch (e) {
      return null;
    }
  }

  async getYears(make = null, model = null) {
    try {
      let url = "";

      if ( make && model ) {
        url = `${ inv_server_base }/api/car/filter/years-by-make-models/${ make }/${ model }`;
      } else {
        url = `${ inv_server_base }/api/car/filter/year`;
      }

      const res = await this.http.get(url);

      return res.data;
    } catch (e) {
      return null;
    }
  }

  async getTenCars(company = null) {
    try {
      const res = await this.http.get(`${ inv_server_base }/api/car/ten-cars?company=${ company }`);
      return res.data;
    } catch (e) {
      return null;
    }
  }

  async getFilters() {
    try {
      const [makes, models, bodies, years, prices] = await Promise.all([
        this.getMakes(),
        this.getModels(),
        this.getBody(),
        this.getYears(),
        this.getPrices()
      ]);
      return { error: false, data: { makes, models, bodies, years, prices } };

    } catch (e) {
      return null;
    }
  }

  async getByYear(year) {
    try {
      const res = await this.http.get(`${ inv_server_base }/api/car/query-limit-skip-cars?Year=${ year }`);
      return res.data;
    } catch (e) {
      return null;
    }
  }

  async getByMake(make) {
    try {
      const res = await this.http.get(`${ inv_server_base }/api/car/query-limit-skip-cars?Make=${ make }`);
      return res.data;
    } catch (e) {
      return null;
    }
  }

  async getByModel(model) {
    try {
      const res = await this.http.get(`${ inv_server_base }/api/car/query-limit-skip-cars?Model=${ model }`);
      return res.data;
    } catch (e) {
      return null;
    }
  }

  async getCarsAndModelsByMake(make) {
    try {
      const [models, cars] = await Promise.all([
        this.getModels(make),
        this.getByMake(make)
      ]);
      return { error: false, data: { models, cars } };
    } catch (e) {
      return null;
    }
  }

  async getYearsAndCarsByModel(model, make = null) {
    try {
      const [years, cars] = await Promise.all([
        this.getYears(make, model),
        this.getByModel(model)
      ]);
      return { error: false, data: { years, cars } };

    } catch (e) {
      return null;
    }
  }

  async createDescription(car) {
    try {
      const res = await this.http.post(`${ inv_server_base }/api/car/create-description`, car);
      return res.data;

    } catch (e) {
      return null;
    }
  }

  async searchCars(params) {
    try {
      let url = `${ inv_server_base }/api/car/search?source=backend`;
      if ( params && params.length > 0 )
        url += `${ params }`;
      const response = await this.http.get(url);
      return response.data
    } catch (e) {
      return null
    }
  }

  async getCarList(limit, skip, query = {}, allowremoved = false) {
    try {
      this.addLoading('car-list');
      const response = await this.http.get(`${ inv_server_base }/api/car?limit=${ limit }&skip=${ skip }`, {
        ...query, allowremoved
      });
      this.delLoading('car-list');
      return response.data;
    } catch (e) {
      this.delLoading('car-list');
      return null
    }
  }

  async globalSearch(limit = '', skip = '', filter = '') {
    try {
      let response = await this.http.get(`${ inv_server_base }/api/car/global-search?limit=${ limit }&skip=${ skip }&filter=${ filter }`);
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async getVINData(vin) {
    try {
      this.addLoading('vin_search');
      let response = await this.http.get(`${ inv_server_base }/api/car/vin/${ vin }`);
      this.delLoading('vin_search')
      return response.data;
    } catch (e) {
      return null
    }
  }

  async createCar(car) {
    try {
      let response = await this.http.post(`${ inv_server_base }/api/car`, car);
      return response;
    } catch (e) {
      return null;
    }
  }

  async retryDownload(carId) {
    try {
      let response = await this.http.post(`${ inv_server_base }/api/files/retry_image_download/${ carId }`);
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async retryAllFailed(companyId = null) {
    try {
      let response = await this.http.post(`${ inv_server_base }/api/files/retry_failed_downloads`, { company: companyId });
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async Import(list, columns, company, userId, locations) {
    try {
      const lStr = JSON.stringify(list);
      const formData = new FormData();
      const blob = new Blob([lStr], { type: 'plain/text' });
      formData.append('file', blob, `unmapped-${ Date.now().toString() }.txt`);
      formData.append('columns', JSON.stringify(columns));
      formData.append('userId', userId);
      formData.append('company', company);
      formData.append('locations', locations);

      const response = await this.http.post(`${ inv_server_base }/api/car/import`, formData);
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async NewImport(data, ftp = false, manually = false) {
    try {
      const response = await this.http.post(`${ data_server_base }/car/import`, { data, ftp, manually });
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async InvokeFeedFromFTP(file) {
    try {
      const res = await this.http.get(`${ inv_server_base }/api/feed/invoke-from-ftp?file=${ file }`);
      return res.data;
    } catch (e) {
      return null;
    }
  }

  async RemoveFeed(file) {
    try {
      const res = await this.http.delete(`${ inv_server_base }/api/feed/${ file }`);
      return res.data;
    } catch (e) {
      return null;
    }
  }

  async GetFacebookFeed(companyId, fields, images) {
    try {
      const res = await this.http.post(`${ inv_server_base }/api/feed/facebook_feed/${ companyId }`, {
        fields,
        images
      });
      return res.data;
    } catch (e) {
      return null;
    }
  }

  async GetFacebookShopFeed(companyId, fields, images) {
    try {
      const res = await this.http.post(`${ inv_server_base }/api/feed/facebook_shop/${ companyId }`, {
        fields,
        images
      });
      return res.data;
    } catch (e) {
      return null;
    }
  }

  async GetAdWordsFeed(companyId, fields, images) {
    try {
      const res = await this.http.post(`${ inv_server_base }/api/feed/ads/${ companyId }`, { fields, images });
      return res.data;
    } catch (e) {
      return null;
    }
  }

  async Export(fields, company, extension, images) {
    try {
      let response = await this.http.post(`${ inv_server_base }/api/car/export`, {
        fields,
        company,
        extension,
        images
      });
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async delete(id) {
    try {
      let response = await this.http.delete(`${ inv_server_base }/api/car/${ id }`);
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async archive(id) {
    try {
      let response = await this.http.delete(`${ inv_server_base }/api/car/${ id }?archive=true`);
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async selectImage(id, image) {
    try {
      let response = await this.http.put(`${ inv_server_base }/api/car/select_image`, {
        id: id,
        image: image
      });
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async edit(car, sortImages = null) {
    try {
      const response = await this.http.put(`${ inv_server_base }/api/car`, sortImages !== null ? {
        car: car,
        images: sortImages
      } : { car });

      return response.data;
    } catch (e) {
      return null;
    }
  }

  async restore(id) {
    try {
      let response = await this.http.put(`${ inv_server_base }/api/car/${ id }`);
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async updateEmptyPhotos(data) {
    try {
      let response = await this.http.put(`${ inv_server_base }/api/files/update_empty_photos`, { photos: data });
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async uploadLegacy(data) {
    try {
      const response = await this.http.upload(`${ files_server_base }/api/files/upload`, data);
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async uploadImage(data) {
    try {
      const response = await this.http.upload(`${ files_server_base }/api/files/s3/upload/image`, data);
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async uploadZoom(data) {
    try {
      let response = await this.http.upload(`${ files_server_base }/api/files/upload_zoom`, data);
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async uploadFrame(data) {
    try {
      let response = await this.http.upload(`${ files_server_base }/api/files/upload_frame`, data);
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async ReadCarFile(file) {
    try {
      let response = await this.http.post(`${ data_server_base }/car/readfile`, file);
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async saveImages(vin, files) {
    try {
      const response = await this.http.post(`${ inv_server_base }/api/car/images`, { vin: vin, files: files });
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async saveInternalImages(vin, files) {
    try {
      const response = await this.http.post(`${ inv_server_base }/api/car/internal-images`, { vin: vin, files: files });
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async saveVideos(vin, videos) {
    try {
      let response = await this.http.post(`${ inv_server_base }/api/car/videos`, { vin: vin, videos: videos });
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async removeCarImageByVin(vin, { imageId, imagesType }) {
    try {
      const response = await this.http.delete(`${ inv_server_base }/api/car/image/${ vin }`, { imageId, imagesType });
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async removeCarImagesByVin(vin, imagesType) {
    try {
      const response = await this.http.delete(`${ inv_server_base }/api/car/images/${ vin }`, { imagesType });
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async removeAllCarsImages() {
    try {
      let response = await this.http.delete(`${ inv_server_base }/api/car/images`);
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async removeAllCars() {
    try {
      let response = await this.http.delete(`${ inv_server_base }/api/car/all`);
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async setBulkPrices(basePrice = '', selectedPrices = '', discountType = '', appliedDiscount = '', filter = '') {
    try {
      const response = await this.http.put(`${ inv_server_base }/api/car/prices`, {
        basePrice,
        selectedPrices,
        discountType,
        appliedDiscount,
        filter
      });
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async setBulkPayments(config, filter) {
    try {
      if ( filter ) {
        config.filter = filter;
      }

      const response = await this.http.put(`${ inv_server_base }/api/car/payments`, config);
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async getPaymentSettings() {
    try {
      const response = await this.http.get(`${ inv_server_base }/api/car/payments/settings`);
      return response.data;
    } catch (e) {
      return null;
    }
  }

  //region RAW DATA
  async getVariations(limit = 10, skip = 0, Make = null, Model = null, Year = null) {
    try {
      let url = `${ inv_server_base }/api/raw?limit=${ limit }&skip=${ skip }`;

      if ( Make )
        url += `&Make=${ Make }`;
      if ( Model )
        url += `&Model=${ Model }`;
      if ( Year )
        url += `&Year=${ Year }`;

      const response = await this.http.get(url);
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async getRawMakes(query = {}) {
    try {
      const res = await this.http.get(`${ inv_server_base }/api/raw/makes`, query);
      return res.data;
    } catch (e) {
      return null;
    }
  }

  async getRawModels(query = {}) {
    try {
      const res = await this.http.get(`${ inv_server_base }/api/raw/models`, query);
      return res.data;
    } catch (e) {
      return null;
    }
  }

  async getRawTrims(query = {}) {
    try {
      const res = await this.http.get(`${ inv_server_base }/api/raw/trims`, query);
      return res.data;
    } catch (e) {
      return null;
    }
  }

  async getRawEngines(query = {}) {
    try {
      const res = await this.http.get(`${ inv_server_base }/api/raw/engines`, query);
      return res.data;
    } catch (e) {
      return null;
    }
  }

  async getRawExtColors(query = {}) {
    try {
      const res = await this.http.get(`${ inv_server_base }/api/raw/ext-colors`, query);
      return res.data;
    } catch (e) {
      return null;
    }
  }

  async getRawIntColors(query = {}) {
    try {
      const res = await this.http.get(`${ inv_server_base }/api/raw/int-colors`, query);
      return res.data;
    } catch (e) {
      return null;
    }
  }

  async getRawTransmissions(query = {}) {
    try {
      const res = await this.http.get(`${ inv_server_base }/api/raw/transmissions`, query);
      return res.data;
    } catch (e) {
      return null;
    }
  }

  async getRawDrivetrains(query = {}) {
    try {
      const res = await this.http.get(`${ inv_server_base }/api/raw/drive-trains`, query);
      return res.data;
    } catch (e) {
      return null;
    }
  }

  async getRawBodies(query = {}) {
    try {
      const res = await this.http.get(`${ inv_server_base }/api/raw/bodies`, query);
      return res.data;
    } catch (e) {
      return null;
    }
  }

  //endregion

  async statistic() {
    try {
      const res = await this.http.get(`${ inv_server_base }/api/car/statistic`);
      return res.data;
    } catch (e) {
      return null;
    }
  }

  async count(dateRange, agent = null) {
    try {
      const response = await this.http.get(`${ inv_server_base }/api/car/statistic/count`, { dateRange, agent });
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async areaChart(dateView, agent = null) {
    try {
      const response = await this.http.get(`${ inv_server_base }/api/car/statistic/area-chart`, { dateView, agent });
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async UnmappedBodies(list, key) {
    try {
      const lStr = JSON.stringify(list);
      const formData = new FormData();
      const blob = new Blob([lStr], { type: 'plain/text' });
      formData.append('file', blob, `unmapped-${ Date.now().toString() }.txt`);
      formData.append('key', key);


      const response = await this.http.post(`${ inv_server_base }/api/body-map/unmapped`, formData);
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async NativeBodies() {
    try {
      const response = await this.http.get(`${ inv_server_base }/api/body-map`);
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async MappedBodies() {
    try {
      const response = await this.http.get(`${ inv_server_base }/api/body-map/bodies`);
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async MappedBodiesWithImages() {
    try {
      const response = await this.http.get(`${ inv_server_base }/api/body-map/bodies-images`);
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async MatchsByBody(name) {
    try {
      const response = await this.http.get(`${ inv_server_base }/api/body-map/body/${ name }`);
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async EditBody(data) {
    try {
      const response = await this.http.put(`${ inv_server_base }/api/body-map/body`, { ...data });
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async RemoveBody(body) {
    try {
      const response = await this.http.delete(`${ inv_server_base }/api/body-map/body`, { body });
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async RemoveMatch(id) {
    try {
      const response = await this.http.delete(`${ inv_server_base }/api/body-map/match/${ id }`);
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async UpdateBodyMapName(body, newName) {
    try {
      const response = await this.http.put(`${ inv_server_base }/api/body-map/body/updateall`, { body, newName });
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async VerifyBody(body) {
    try {
      const response = await this.http.get(`${ inv_server_base }/api/body-map/verify/${ body }`);
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async VerifyMatch(body) {
    try {
      const response = await this.http.get(`${ inv_server_base }/api/body-map/verify/match/${ body }`);
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async SetBody(body) {
    try {
      const response = await this.http.put(`${ inv_server_base }/api/body-map`, body);
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async ImportRaw(data) {
    try {
      const response = await this.http.post(`${ data_server_base }/raw/import`, data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      return response.data;
    } catch (error) {
      return null;
    }
  }

  async GetCarsByVIN(cars) {
    try {
      this.addLoading('list_by_vin');
      const response = await this.http.post(`${ inv_server_base }/api/car/cars-by-vin`, { cars });
      this.delLoading('list_by_vin');
      return response.data;
    } catch (e) {
      this.delLoading('list_by_vin');
      return null;
    }
  }

  async GetBodiesMapByField(path, ext, field) {
    try {
      this.addLoading('bodies_match_search');
      const response = await this.http.post(`${ data_server_base }/car/bodies-by-field`, { path, ext, field })
      this.delLoading('bodies_match_search');
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async getManufacturer(id) {
    try {
      const response = await this.http.get(`${ inv_server_base }/api/raw/manufacturer?car=${ id }`);
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async uploadCarBodyImage(data) {
    try {
      const response = await this.http.post(`${ inv_server_base }/api/body-map/image`, data);
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async DecodeVin(vin) {
    try {
      this.addLoading('decode');
      const res = await this.http.get(`${ inv_server_base }/api/decode/vin/${ vin }`);
      this.delLoading('decode');
      return res.data;
    } catch (e) {
      this.delLoading('decode');
      return null;
    }
  }

  async DecodePlate({ plate, state }) {
    try {
      this.addLoading('decode');
      const res = await this.http.get(`${ inv_server_base }/api/decode/plate/${ plate }?state=${ state }`);
      this.delLoading('decode');
      return res.data;
    } catch (e) {
      this.delLoading('decode');
      return null;
    }
  }
}

export default InventoryService;